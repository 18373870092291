<template>
  <div class="loginForm_container">
    <div class="home-card" id="login-card">
      <v-row no-gutters>
        <v-col cols="12" md="6" sm="6" class="text-center">
          <img alt="Logo" src="../assets/images/signin-image.jpg" />
        </v-col>
        <v-col
          cols="12"
          class="text-center bottomSection_left d-block d-sm-none"
        >
          <p class="mt-0 mb-6">
            <strong>
              <a href="javascript:void(0);" @click="registerPage()"
                >Create an Account</a
              >
              <br />
              <a :href="website">Take me back</a>
            </strong>
          </p>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <h1>Sign In</h1>
          <v-form
            ref="form"
            class="loginForm"
            v-model="valid"
            lazy-validation
            @submit.prevent="login"
          >
            <v-text-field
              placeholder=" "
              label="Email"
              v-model="email"
              type="email"
              class="mb-1"
              :rules="emailRules"
              outlined
              dense
              autofocus
            >
              <v-icon slot="append"> mdi-email</v-icon>
            </v-text-field>
            <v-text-field
              placeholder=" "
              v-model="password"
              label="Password"
              type="password"
              :rules="passwordRules"
              outlined
              dense
            >
              <v-icon slot="append"> mdi-lock</v-icon>
            </v-text-field>

            <v-row no-gutters class="remember_forgot_parent">
              <v-col cols="6" sm="6" md="6">
                <v-checkbox
                  v-model="checkbox"
                  :label="`Remember me`"
                ></v-checkbox>
              </v-col>
              <!--              <v-col cols="6" md="6" class="text-right">-->
              <!--                <a class="forgotPassword" href="javascript:void(0);"-->
              <!--                  >Forgot password</a-->
              <!--                >-->
              <!--              </v-col>-->
            </v-row>

            <div class="loginBtn">
              <v-btn
                type="submit"
                class="button is-dark"
                name="button"
                :disabled="!valid"
              >
                {{ $t("login.login") }}
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
          sm="6"
          class="text-center bottomSection_left d-none d-sm-block"
        >
          <p class="mt-11 mt-sm-1">
            <strong>
              <a href="javascript:void(0);" @click="registerPage()"
                >Create an Account</a
              >
              <br />
              <a :href="website">Take me back</a>
            </strong>
          </p>
        </v-col>
        <!--        <v-col cols="12" md="6" sm="6">-->
        <!--          <div class="socialMedia_login">-->
        <!--            <ul>-->
        <!--              <li>-->
        <!--                <p><strong>Or login with </strong></p>-->
        <!--              </li>-->
        <!--              <li>-->
        <!--                <a href="#"><v-icon slot="append"> mdi-facebook</v-icon></a>-->
        <!--              </li>-->
        <!--              <li>-->
        <!--                <a href="#"><v-icon slot="append"> mdi-twitter</v-icon></a>-->
        <!--              </li>-->
        <!--              <li>-->
        <!--                <a href="#"><v-icon slot="append"> mdi-google</v-icon></a>-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <!--        </v-col>-->
      </v-row>
    </div>
  </div>
</template>
<script>
import { ACTION_LOGIN } from "../store/modules/auth";
import { mapActions } from "vuex";

export default {
  data: () => {
    return {
      website: process.env.VUE_APP_WEBSITE,
      checkbox: false,
      emailRules: [
        (value) => (!!value && !!value.trim()) || "Email is required.",
        (value) => {
          const pattern =
            /^(([^<>()#$%&*{}~=[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      ],
      passwordRules: [
        (v) => !!v || "Password is required.",
        (v) => (v && !/^\s*$/.test(v)) || "Only spaces are not allowed"
      ],
      valid: false,
      email: "",
      password: "",
      error: null
    };
  },
  props: {
    isLoggedIn: {
      type: Boolean
    }
  },
  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState === "complete") {
        document.getElementById("login-card").click();
      }
    };
  },
  methods: {
    ...mapActions("auth", {
      action_login: ACTION_LOGIN
    }),
    registerPage() {
      this.$router.push("/register");
    },
    async login() {
      if (this.$refs.form.validate()) {
        await this.action_login({
          email: this.email,
          password: this.password
        });
      }
    }
  }
};
</script>

<style lang="scss">
.loginForm_container {
  position: relative;
  display: block;
  min-width: 100vw;
  min-height: 100vh;
  margin-top: -68px !important;

  .home-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-right: -50%;
    width: 900px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 25px;
    padding-top: 65px;
    padding-bottom: 40px;

    h1 {
      font-weight: bold;
      font-size: 32px;
      line-height: 35px;
      color: #000;
      margin-bottom: 40px;
      margin-left: 25px;
    }

    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 26px;
      margin-top: 15px;

      a {
        color: #23bdcb;
      }
    }

    .socialMedia_login {
      ul {
        display: block;
        padding-left: 0;
        list-style: none;
        margin-top: 25px;
        margin-left: 25px;

        li {
          display: inline-block;
          list-style: none;
          padding: 5px;
          vertical-align: middle;

          a {
            text-decoration: none;

            .mdi-facebook {
              color: #38529a;
            }

            .mdi-twitter {
              color: #5aa4d6;
            }

            .mdi-google {
              color: #e34133;
            }
          }
        }
      }
    }

    .loginForm {
      max-width: 300px;
      margin: 25px 0px 25px 25px;

      .v-input {
        fieldset {
          color: #999;
          background: #ffffff;
          //box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
          border-radius: 0px;
          border: none;
          border-bottom: 1px solid #000;
        }

        label {
          color: #999;
          font-size: 14px;
        }

        .v-icon {
          font-size: 18px;
          color: #000;
        }
      }

      .remember_forgot_parent {
        .v-input--checkbox {
          margin-top: 0;
          padding-top: 0;

          .v-icon {
            color: #cdcdcd;
          }

          label {
            color: #2c2c2c;
            font-size: 13px;
          }
        }

        a {
          color: #23bdcb;
          font-size: 13px;
        }
      }

      .loginBtn {
        margin-top: 1.5rem;
        text-align: left;

        button {
          background: #6dabe4;
          border-radius: 4px;
          min-width: 140px;
          min-height: 50px;
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          box-shadow: none !important;
          text-transform: capitalize;
        }
      }
    }
  }
}

.input {
  border: 2px solid #ffb581;
}

.input:focus,
.button:focus {
  border-color: #ffa95e;
  box-shadow: 0 0 0 0.125em rgba(220, 138, 50, 0.25);
}

.error {
  color: #ff4a4a;
}

/* ------------For mobile devices------------- */

@media (max-width: 991px) {
  .loginForm_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    margin-top: 0 !important;

    .home-card {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      padding-top: 25px;
      text-align: center;

      > div:nth-child(1) {
        flex-direction: column-reverse;
      }

      h1 {
        margin-top: 15px;
        margin-left: 0;
      }
      .loginForm {
        min-width: 100%;
        margin-left: 0;
        margin-bottom: 40px;

        .loginBtn {
          text-align: center;
        }
      }

      .bottomSection_left {
        p {
          text-align: center;
          margin-bottom: 0;
        }
      }

      .socialMedia_login {
        ul {
          margin-top: 0;
          margin-left: 0;
          text-align: center;
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .loginForm_container {
    .home-card {
      > div:nth-child(1) {
        align-items: center;
        flex-direction: row;
      }
    }
  }
}
</style>
