<template>
  <div class="home-container">
    <div class="loader" v-if="isLoading"></div>
    <login-card :isLoggedIn="isLoggedIn" />
  </div>
</template>

<script>
import LoginCard from "../components/LoginCard.vue";
export default {
  data() {
    return {
      isLoading: false,
      isLoggedIn: false
    };
  },
  components: { LoginCard },
  computed: {}
};
</script>

<style scoped>
/* ------------For mobile devices------------- */

@media (max-width: 480px) {
}
</style>
